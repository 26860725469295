<template>
  <section>
    <InvoiceModal :trip="invoiceModalDetails" :symbol="currencySymbol" />
    <AcceptModal
      v-if="showAcceptModal"
      :rent-id="currentRentId"
      :currentVehicleCategory="currentVehicleCategory"
      v-on:close="onAcceptModalClose"
    />
    <RejectModal
      v-if="showRejectModal"
      :rent-id="currentRentId"
      v-on:close="onRejectModalClose"
    />
    <CancelModal
      v-if="showCancelModal"
      :rent-id="currentRentId"
      v-on:close="onCancelModalClose"
    />
    <DeliverModal
      v-if="showDeliverModal"
      :rent-id="currentRentId"
      v-on:close="onDeliverModalClose"
    />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div>
            <TitlePlus title="Vehicle Rents" :hide-plus="true" />
          </div>

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row">
          <SummaryCard
            title="Total Rents"
            :value="`${indexMetaData.summary.total}`"
            variant="blue"
            size="small"
          />

          <SummaryCard
            title="Pending"
            :value="` ${indexMetaData.summary.requested}`"
            variant="yellow"
            size="small"
          />

          <SummaryCard
            title="Accepted"
            :value="
              ` ${indexMetaData.summary.accepted +
                indexMetaData.summary.delivered}`
            "
            variant="green"
            size="small"
          />

          <SummaryCard
            title="Rejected/Cancelled"
            :value="
              ` ${indexMetaData.summary.rejected +
                indexMetaData.summary.cancelled}`
            "
            variant="red"
            size="small"
          />

          <SummaryCard
            title="Completed"
            :value="` ${indexMetaData.summary.completed}`"
            variant="green"
            size="small"
          />
        </div>
      </div>

      <div
        v-if="$acl.canNotView('trips')"
        class="my-5 font-bold text-center text-gray-600"
      >
        You do not have enough permission to view it.
      </div>
      <div v-else>
        <FSTable
          :qso="qso"
          :fst-id="fstId"
          :headers="getTableHeadersName"
          :endpoint="endpoint"
          :currency-enabled="false"
          :currency-attributes="getCurrencyAttributes"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #topLeft="{ slotWidth }">
            <keep-alive>
              <FSTableFilter
                :fst-id="fstId"
                :slot-width="slotWidth"
                :options="filterOptions"
              />
            </keep-alive>
          </template>

          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow
                  :key="itemIndex"
                  :className="{
                    'fst-row': true,
                  }"
                  text-fallback-always
                >
                  <FSTableRowItem>
                    <router-link
                      :to="{
                        name: 'VehicleRentalDetail',
                        params: { id: item.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      {{ item.id.substr(item.id.length - 5) }}
                    </router-link>
                  </FSTableRowItem>

                  <FSTableRowItem
                    v-if="haveRiderColumn"
                    :text="item.rider.full_name"
                    :to="{
                      name: 'ViewRiderUserProfile',
                      params: { id: item.rider.id },
                    }"
                  />

                  <FSTableRowItem
                    :text="item.vehicle.qr_code"
                    :to="{
                      name: 'ViewVehicleProfile',
                      params: { id: item.vehicle.id },
                    }"
                    v-if="item.vehicle"
                  />

                  <FSTableRowItem text="--" v-if="!item.vehicle" />

                  <FSTableRowItem
                    :text="
                      getFormattedDateTime(
                        item.start_time,
                        'D MMM, YY hh:mm:ss a'
                      )
                    "
                  />

                  <FSTableRowItem
                    :text="
                      getFormattedDateTime(
                        item.end_time,
                        'D MMM, YY hh:mm:ss a'
                      )
                    "
                  />

                  <FSTableRowItem
                    :text="
                      milisecToDHMSConverter(item.duration_in_seconds * 1000)
                    "
                  />

                  <FSTableRowItem :text="getCostLine(item)" />

                  <FSTableRowItem
                    :text="item.invoice.payment_method"
                    v-if="item.invoice"
                  />

                  <FSTableRowItem>
                    <XStatus
                      :text="getRentStatus(item.status)"
                      :variant="getRentStatusBadge(item.status)"
                      :profile="`trip`"
                      v-if="!shouldShowStatusTooltip(item.status)"
                    />
                    <XStatus
                      :text="getRentStatus(item.status)"
                      :variant="getRentStatusBadge(item.status)"
                      :profile="`trip`"
                      v-if="shouldShowStatusTooltip(item.status)"
                      v-tooltip.bottom="getTooltipText(item)"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <div class="flex items-center">
                      <oto-eye-icon
                        v-tooltip.bottom="'View Invoice'"
                        @click="onInvoiceModalReq(item, false)"
                      />

                      <t-dropdown
                        v-if="
                          item.status === 'REQUESTED' ||
                            item.status === 'ACCEPTED' ||
                            item.status === 'DELIVERED' ||
                            item.status === 'TRIP_STARTED'
                        "
                        variant="smActions"
                      >
                        <template
                          #trigger="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                    }"
                        >
                          <button
                            class="focus:outline-none"
                            aria-label="More Actions"
                            aria-haspopup="true"
                            @mousedown="mousedownHandler"
                            @focus="focusHandler"
                            @blur="blurHandler"
                            @keydown="keydownHandler"
                          >
                            <svg
                              class="w-6 h-5 mt-1 text-oGray hover:text-oDark"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                              ></path>
                            </svg>
                          </button>
                        </template>

                        <template #default="{ hide }">
                          <div
                            @click="hide()"
                            class="py-1 mt-px bg-white rounded-md shadow-md"
                          >
                            <t-dropdown-item
                              @click="
                                onPerformAction({
                                  id: item.id,
                                  vehicle_category: item.vehicle_category,
                                  action: 'accept',
                                })
                              "
                              v-if="item.status === 'REQUESTED'"
                            >
                              Accept
                            </t-dropdown-item>
                            <t-dropdown-item
                              @click="
                                onPerformAction({
                                  id: item.id,
                                  action: 'reject',
                                })
                              "
                              v-if="item.status === 'REQUESTED'"
                            >
                              Reject
                            </t-dropdown-item>
                            <t-dropdown-item
                              @click="
                                onPerformAction({
                                  id: item.id,
                                  action: 'deliver',
                                })
                              "
                              v-if="item.status === 'ACCEPTED'"
                            >
                              Mark as Delivered
                            </t-dropdown-item>
                            <t-dropdown-item
                              @click="
                                onPerformAction({
                                  id: item.id,
                                  action: 'cancel',
                                })
                              "
                              v-if="
                                item.status !== 'REQUESTED' &&
                                  item.status !== 'ACCEPTED' &&
                                  item.status !== 'TRIP_COMPLETED' &&
                                  item.status !== 'CANCELLED' &&
                                  item.status !== 'REJECTED'
                              "
                            >
                              Cancel
                            </t-dropdown-item>
                          </div>
                        </template>
                      </t-dropdown>
                    </div>
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  :className="{
                    'fst-row': true,
                  }"
                  text-fallback-always
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <router-link
                      :to="{
                        name: 'VehicleRentalDetail',
                        params: { id: item.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      {{ item.id.substr(item.id.length - 5) }}
                    </router-link>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <router-link
                      :to="{
                        name: 'ViewVehicleProfile',
                        params: { id: item.vehicle.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                      v-if="item.vehicle"
                    >
                      {{ item.vehicle.qr_code }}
                    </router-link>
                    <span v-else>--</span>
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <XStatus
                      :text="getRentStatus(item.status)"
                      :variant="getRentStatusBadge(item.status)"
                      :profile="`trip`"
                      v-if="!shouldShowStatusTooltip(item.status)"
                    />
                    <XStatus
                      :text="getRentStatus(item.status)"
                      :variant="getRentStatusBadge(item.status)"
                      :profile="`trip`"
                      v-if="shouldShowStatusTooltip(item.status)"
                      v-tooltip.bottom="getTooltipText(item)"
                    />
                  </FSTableRowItem>
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Start Time</div>
                      <div class="col-span-5 right-text">
                        {{
                          getFormattedDateTime(
                            item.start_time,
                            'D MMM, YY hh:mm:ss a'
                          )
                        }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">End Time</div>
                      <div class="col-span-5 right-text">
                        {{
                          getFormattedDateTime(
                            item.end_time,
                            'D MMM, YY hh:mm:ss a'
                          )
                        }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Duration</div>
                      <div class="col-span-5 right-text">
                        {{ getDuration(item.duration, 'hours') }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Charge</div>
                      <div class="col-span-5 right-text">
                        {{ getCostLine(item) }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Payment Method</div>
                      <div class="col-span-5 right-text">
                        {{ item.invoice.payment_method || `--` }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Actions</div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          <oto-eye-icon
                            v-tooltip.bottom="'View Invoice'"
                            @click="onInvoiceModalReq(item, false)"
                          />

                          <t-dropdown
                            v-if="
                              item.status === 'REQUESTED' ||
                                item.status === 'ACCEPTED' ||
                                item.status === 'DELIVERED' ||
                                item.status === 'TRIP_STARTED'
                            "
                            variant="smActions"
                          >
                            <template
                              #trigger="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                    }"
                            >
                              <button
                                class="focus:outline-none"
                                aria-label="More Actions"
                                aria-haspopup="true"
                                @mousedown="mousedownHandler"
                                @focus="focusHandler"
                                @blur="blurHandler"
                                @keydown="keydownHandler"
                              >
                                <svg
                                  class="w-6 h-5 mt-1 text-oGray hover:text-oDark"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                  ></path>
                                </svg>
                              </button>
                            </template>

                            <template #default="{ hide }">
                              <div
                                @click="hide()"
                                class="py-1 mt-px bg-white rounded-md shadow-md"
                              >
                                <t-dropdown-item
                                  @click="
                                    onPerformAction({
                                      id: item.id,
                                      vehicle_category: item.vehicle_category,
                                      action: 'accept',
                                    })
                                  "
                                  v-if="item.status === 'REQUESTED'"
                                >
                                  Accept
                                </t-dropdown-item>
                                <t-dropdown-item
                                  @click="
                                    onPerformAction({
                                      id: item.id,
                                      action: 'reject',
                                    })
                                  "
                                  v-if="item.status === 'REQUESTED'"
                                >
                                  Reject
                                </t-dropdown-item>
                                <t-dropdown-item
                                  @click="
                                    onPerformAction({
                                      id: item.id,
                                      action: 'deliver',
                                    })
                                  "
                                  v-if="item.status === 'ACCEPTED'"
                                >
                                  Mark as Delivered
                                </t-dropdown-item>
                                <t-dropdown-item
                                  @click="
                                    onPerformAction({
                                      id: item.id,
                                      action: 'cancel',
                                    })
                                  "
                                  v-if="
                                    item.status !== 'REQUESTED' &&
                                      item.status !== 'ACCEPTED' &&
                                      item.status !== 'TRIP_COMPLETED' &&
                                      item.status !== 'CANCELLED' &&
                                      item.status !== 'REJECTED'
                                  "
                                >
                                  Cancel
                                </t-dropdown-item>
                              </div>
                            </template>
                          </t-dropdown>
                        </div>
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </div>
    </content-section>

    <EdgeStack
      :id="tripAction.esId"
      :busy="tripAction.busy"
      :exit-button="{ text: 'Cancel', hidden: true }"
      :footer-class="{
        fixed: 'flex justify-end items-center px-6 pt-2 pb-5 w-full h-auto',
        append: '',
      }"
    >
      <template #header>
        <div class="text-xl font-bold">{{ tripAction.title }}</div>
      </template>

      <template #footer="{ close }">
        <div class="flex items-center gap-5">
          <AppButton text="Cancel" variant="secondary" @click="close()" />

          <AppButton
            text="Confirm"
            :is-loading="tripAction.processing"
            @click="
              onPerformAction({
                tripId: tripAction.primaryKey,
                action: 'execute',
              })
            "
          />
        </div>
      </template>

      <template #default>
        <div class="flex items-center px-3">
          <div class="w-14">
            <div
              class="flex items-center justify-center bg-gray-200 rounded-full w-9 h-9"
            >
              <img
                :src="tripAction.data.user.profile_pic"
                class="object-contain rounded-full w-9 h-9"
              />
            </div>
          </div>

          <div class="flex items-center justify-between w-full py-4 border-b">
            <div>
              <h5
                v-text="tripAction.data.user.full_name"
                class="text-base text-black"
              />
              <h6
                v-text="'UserID: ' + $truncate(tripAction.data.user.id, -5)"
                class="text-sm tracking-wide"
                style="color: #797979"
              />
            </div>

            <div
              class="text-right text-blue-600"
              v-text="`TripID: ${$truncate(tripAction.primaryKey, -5)}`"
            />
          </div>
        </div>

        <div class="flex items-center px-3">
          <div class="w-14">
            <div class="flex items-center justify-center rounded-full w-9 h-9">
              <img src="@/assets/icon/rewind-circle.svg" />
            </div>
          </div>

          <div class="flex items-center justify-between w-full py-4 border-b">
            <div>
              <h5
                class="text-base text-black"
                v-text="`Forcibly lock vehicle`"
              />
              <h6
                v-text="
                  `If you select YES then System will lock the vehicle Forcefully.`
                "
                class="text-sm tracking-wide"
                style="color: #797979"
              />
            </div>

            <div class="flex items-center">
              <p
                class="w-8 text-sm text-oDark"
                v-text="tripAction.models.trigger_lock ? 'Yes' : 'No'"
              />
              <TToggle
                v-model="tripAction.models.trigger_lock"
                variant="success"
              />
            </div>
          </div>
        </div>

        <div class="flex items-center px-3">
          <div class="w-14">
            <div class="flex items-center justify-center rounded-full w-9 h-9">
              <img src="@/assets/icon/plus-minus-box.svg" />
            </div>
          </div>

          <div class="flex items-center justify-between w-full py-4 border-b">
            <div>
              <h5
                class="text-base text-black"
                v-text="`Trigger payment for rider`"
              />
              <h6
                v-text="
                  `If you select Yes then Rider will be charged manually.`
                "
                class="text-sm tracking-wide"
                style="color: #797979"
              />
            </div>

            <div class="flex items-center">
              <p
                class="w-8 text-sm text-oDark"
                v-text="tripAction.models.trigger_payment ? 'Yes' : 'No'"
              />
              <TToggle
                v-model="tripAction.models.trigger_payment"
                variant="success"
              />
            </div>
          </div>
        </div>

        <div
          v-if="tripAction.models.trigger_payment"
          class="flex justify-end px-2 my-2"
        >
          <div
            class="max-w-md py-4 bg-gray-200 border rounded-md"
            style="min-width: 350px; background: #F3F3F3;"
          >
            <section class="flex items-center justify-between gap-4 px-4">
              <div class="-mt-6">Trip Duration (mins)</div>

              <div class="w-24">
                <AppInput
                  v-model="tripAction.models.riding_time_min"
                  type="number"
                />
              </div>
            </section>

            <section class="py-2 border-t border-gray-300">
              <div class="flex items-center justify-between px-4 py-1">
                <div class="w-full">
                  Reservation Fee
                </div>

                <div class="w-12">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.vehicle_reservation_fees
                  }}
                </div>
              </div>

              <div class="flex items-center justify-between px-4 py-1">
                <div class="w-full">
                  Unlock Fee
                </div>

                <div class="w-12">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.vehicle_unlock_fees
                  }}
                </div>
              </div>

              <div class="flex items-center justify-between px-4 py-1">
                <div class="w-full">
                  Trip Duration Fee ({{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.default_fees_per_minute
                  }}
                  x {{ tripAction.models.riding_time_min }} mins)
                </div>

                <div class="w-12">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.trip_amount
                  }}
                </div>
              </div>

              <div class="flex items-center justify-between px-4 py-1">
                <div class="w-full">
                  Discount
                </div>

                <div class="w-12 text-oGreen">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.discount_data
                  }}
                </div>
              </div>
            </section>

            <section class="py-2 border-t border-gray-300">
              <div class="flex items-center justify-between px-4 py-1">
                <div class="w-full">
                  Subtotal
                </div>

                <div class="w-12">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.total_amount
                  }}
                </div>
              </div>
              <div class="flex items-center justify-between px-4 py-1">
                <div class="w-full">Vat ({{ '0' }}%)</div>

                <div class="w-12">
                  {{ getTripActionCharges.currency_symbol + '0.00' }}
                </div>
              </div>
            </section>

            <section class="pt-3 border-t border-gray-300">
              <div class="flex items-center justify-between px-4">
                <div class="w-full">
                  Total
                </div>

                <div class="w-20 text-3xl text-center">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.total_amount
                  }}
                </div>
              </div>
            </section>
          </div>
        </div>

        <div class="flex px-3 py-3">
          <div class="w-14">
            <div class="flex items-center justify-center rounded-full w-9 h-9">
              <img src="@/assets/icon/note.svg" />
            </div>
          </div>

          <div class="w-full">
            <h5
              class="text-base text-black"
              v-text="`Adjustment Note (Optional)`"
            />
            <AppInput type="textarea" v-model="tripAction.models.remarks" />
          </div>
        </div>
      </template>
    </EdgeStack>
  </section>
</template>

<script>
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
  generateCurrencyAttributes,
} from '@/components/fs-table'
import { useEndpoints } from '@/composables'
// import { resolveProp } from '@/utils'
import {
  getHumanReadableDuration,
  getFormattedDateTime,
} from '@/utils/datetime'
import EdgeStack from '@/components/modals/EdgeStack'
import TDropdownItem from '@/components/dropdown/TDropdownItem'
import { milisecToDHMSConverter } from '@/components/picker/date-range/utils/DatesUtils'

export default {
  name: 'VehicleRentalIndex',

  props: {
    pageTitle: {
      type: String,
      default: 'Vehicle Rentals',
    },
    fstId: {
      type: String,
      default: 'vehicle-rentals',
    },
    endpoint: {
      type: String,
      default: useEndpoints.vehicleRental.index(),
    },
    haveRiderColumn: {
      type: Boolean,
      default: true,
    },
    qso: {
      type: Object,
      default: () => ({ append: '', prepend: '?' }),
    },
  },

  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    XStatus: () => import('@/components/badge/XStatus'),
    // MoreActionsDropdown: () =>
    //   import('@/components/dropdown/MoreActionsDropdown'),
    OtoEyeIcon: () => import('@/components/ui/OtoEyeIcon'),
    InvoiceModal: () =>
      import('@/composites/vehicle-rental/shared/InvoiceModal.vue'),
    AcceptModal: () => import('@/composites/vehicle-rental/modals/AcceptModal'),
    RejectModal: () => import('@/composites/vehicle-rental/modals/RejectModal'),
    CancelModal: () => import('@/composites/vehicle-rental/modals/CancelModal'),
    DeliverModal: () =>
      import('@/composites/vehicle-rental/modals/DeliverModal'),

    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,

    EdgeStack,
    TDropdownItem,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      currentRentId: null,
      currentVehicleCategory: null,
      showAcceptModal: false,
      showRejectModal: false,
      showCancelModal: false,
      showDeliverModal: false,
      indexMetaData: {
        summary: {
          accepted: 0,
          delivered: 0,
          rejected: 0,
          cancelled: 0,
          completed: 0,
          requested: 0,
          total: 0,
        },
        count: {
          total: 0,
        },
      },

      tableHeaders: [
        { text: 'Rent ID', width: '9%', sort: 'id' },
        { text: 'Rider', width: '10%', sort: null },
        { text: 'Vehicle QR', width: '10%', sort: null },
        { text: 'Start Time', width: '15%', sort: null },
        { text: 'End Time', width: '15%', sort: null },
        { text: 'Duration', width: '6%', sort: null },
        { text: 'Charge', width: '5%', sort: null },
        { text: 'Payment Method', width: '10%', sort: null },
        { text: 'Status', width: '6%', sort: null },
        { text: 'Actions', width: '5%', sort: null },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        { text: 'Rent ID', width: '20%', sort: 'id' },
        { text: 'Vehicle QR', width: '40%', sort: 'bike' },
        { text: 'Charge', width: '30%', sort: 'cost' },
      ],
      opened: [],
      invoiceModalDetails: {},

      // generated by generateFilterOptions()
      filterOptions: [],

      tripAction: {
        busy: false,
        esId: 'tripActionES',
        title: 'Force Complete Trip',

        primaryKey: '',
        processing: false, //

        models: {
          trigger_lock: false,
          trigger_payment: false,
          riding_time_min: 0, // in min
          remarks: '',
        },

        data: {
          from_lock_log: false,
          latest_lock_log: {},
          riding_time_mins: 0,

          user: {
            id: '--',
            full_name: '--',
            role_name: '--',
            profile_pic: 'https://via.placeholder.com/150',
          },

          pricing_info: {
            id: '--',
            bike_category: '-',
            currency_symbol: '~',
            vehicle_reservation_fees: 0,
            vehicle_unlock_fees: 0,
            default_fees_per_minute: 0,
            max_billing_per_ride: 0,
            discount_data: 0,
          },
        },
      },
    }
  },

  computed: {
    getTableHeadersName() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.getTableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    currencySymbol() {
      return this.$org.default_currency.symbol
    },
    getTableHeaders() {
      if (this.haveRiderColumn) {
        return this.tableHeaders
      } else {
        return this.tableHeaders.filter((item) => item.text !== 'Rider')
      }
    },
    getCurrencyAttributes() {
      return generateCurrencyAttributes({
        root: 'invoiced_charges',
        // def: {
        //   amount: 'settled_amount',
        //   currencyName: 'settled_currency.name',
        //   currencySymbol: 'settled_currency.symbol',
        // },
        def: {
          amount: 'amount',
          currencyName: 'currency.name',
          currencySymbol: 'currency.symbol',
        },
        actual: {
          amount: 'amount',
          currencyName: 'currency.name',
          currencySymbol: 'currency.symbol',
        },
      })
    },

    getTripActionCharges() {
      let p = this.tripAction.data.pricing_info
      let t = this.tripAction.models.riding_time_min

      p.vehicle_reservation_fees =
        Number(p.vehicle_reservation_fees).toFixed(2) || Number(0).toFixed(2)

      p.vehicle_unlock_fees =
        Number(p.vehicle_unlock_fees).toFixed(2) || Number(0).toFixed(2)

      p.discount_data =
        Number(p.discount_data).toFixed(2) || Number(0).toFixed(2)

      let trip_amount_raw = Number(p.default_fees_per_minute) * Number(t)
      let trip_amount = Number(trip_amount_raw).toFixed(2)

      let total_amount = (
        Number(trip_amount) +
        Number(p.vehicle_reservation_fees) +
        Number(p.vehicle_unlock_fees) -
        Number(p.discount_data)
      ).toFixed(2)

      return { ...p, trip_amount, total_amount }
    },
  },

  async created() {
    await this.generateFilterOptions()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    getFormattedDateTime,
    onApplyFilterDateRange,
    milisecToDHMSConverter,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    async generateFilterOptions() {
      this.filterOptions = [
        {
          key: 'status',
          type: 'checkbox',
          input: [
            { text: 'Accepted', value: 'ACCEPTED' },
            { text: 'Rejected', value: 'REJECTED' },
            { text: 'Cancelled', value: 'CANCELLED' },
            { text: 'Vehicle Delivered', value: 'DELIVERED' },
            { text: 'Completed', value: 'TRIP_COMPLETED' },
          ],
          title: 'Status',
        },

        {
          key: 'rentCost',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: this.currencySymbol,
            max: 1000,
            minQs: 'min_cost', // query string for min value
            maxQs: 'max_cost', // query string for max value
          },
          title: 'Rent Cost',
        },
      ]
    },
    async onInvoiceModalReq(rentalDetail, downloadOnly = false) {
      this.$notify(
        {
          group: 'generic',
          type: 'default',
          title: `Processing`,
          text: 'The action is being executed...',
        },
        600
      )
      this.invoiceModalDetails = rentalDetail

      if (downloadOnly === true) {
        if (this.$refs.invoiceModal) {
          this.$refs.invoiceModal.exportToPDF()
        }
      } else {
        setTimeout(() => {
          this.$modal.show('invoiceModal')
        }, 2000)
      }
    },

    getDirection() {
      var directionsService = new this.google.maps.DirectionsService()
      var directionsDisplay = new this.google.maps.DirectionsRenderer({
        suppressMarkers: true,
      })

      var vm = this

      directionsService.route(
        {
          origin: this.getLatLng(this.trip.src_coord),
          destination: this.getLatLng(this.trip.dest_coord),
          travelMode: 'DRIVING',
        },
        function(response, status) {
          if (status === 'OK') {
            directionsDisplay.setDirections(response)
            var leg = response.routes[0].legs[0]
            vm.makeMarker(leg.start_location, 'start', 'title')
            vm.makeMarker(leg.end_location, 'end', 'title')
          } else {
            vm.makeMarker(response.request.origin.location, 'start', 'title')
            //window.alert('Directions request failed due to ' + status);
          }
        }
      )
    },
    makeMarker(position, icon, title) {
      new this.google.maps.Marker({
        position: position,
        map: this.$refs.map.$mapObject,
        icon:
          icon == 'start'
            ? {
                url: require(`@/assets/img/start_place.png`),
                scaledSize: { width: 63, height: 73 },
              }
            : {
                url: require(`@/assets/img/end_place.png`),
                scaledSize: { width: 63, height: 73 },
              },
        title: title,
      })
    },
    getLatLng(position) {
      if (position == '' || position == null)
        return {
          lat: 0,
          lng: 0,
        }
      let lat = parseFloat(position.split(',')[0])
      let lng = parseFloat(position.split(',')[1])
      return {
        lat: lat,
        lng: lng,
      }
    },
    getDuration(...args) {
      return getHumanReadableDuration(...args)
    },
    getCostLine(item) {
      if (!item.invoice || typeof item.invoice === 'string') {
        return '--'
      }

      return `${item.invoice.currency.symbol} ${item.invoice.amount}`
    },
    getActionOptions(status) {
      console.log(status)
      return {
        // cancel: "Cancel",
        // end: "End"
      }
      // return status in statuses ? statuses[status] : "-";
    },
    getTooltipText({ status, cancel_reason, reject_reason }) {
      const tooltipTexts = {
        CANCELLED: `Reason: ${cancel_reason || 'N/A'}`,
        REJECTED: `Reason: ${reject_reason || 'N/A'}`,
      }
      return tooltipTexts[status] || '--'
    },
    shouldShowStatusTooltip(status) {
      return status === 'CANCELLED' || status === 'REJECTED'
    },
    getRentStatus(status) {
      const statuses = {
        REQUESTED: 'Pending',
        ACCEPTED: 'Accepted',
        CANCELLED: 'Cancelled',
        REJECTED: 'Rejected',
        TRIP_COMPLETED: 'Completed',
        DELIVERED: 'Rent Started',
      }
      return status in statuses ? statuses[status] : '-'
    },
    getRentStatusBadge(status) {
      const badges = {
        REQUESTED: 'orange',
        ACCEPTED: 'blue',
        CANCELLED: 'red',
        REJECTED: 'blush',
        TRIP_COMPLETED: 'green',
        DELIVERED: 'purple',
      }
      return status in badges ? badges[status] : 'light-blue'
    },
    view(item) {
      console.log('item = ', item)
      this.$router.push(`/user-management/${item.trip_short_id}`)
    },
    getActionsEndpoint(id) {
      return useEndpoints.trip.update(id)
    },

    async onPerformAction({ id, action, vehicle_category } = {}) {
      if (this.$acl.canNotUpdate('trips')) {
        this.$notify(this.$acl.notAbleToToast())
        return
      }

      console.log('onPerformAction', id, action)

      if (action === 'accept') {
        this.currentRentId = id
        this.currentVehicleCategory = vehicle_category
        this.showAcceptModal = true
        return
      }

      if (action === 'reject') {
        this.currentRentId = id
        this.showRejectModal = true
        return
      }

      if (action === 'deliver') {
        this.currentRentId = id
        this.showDeliverModal = true
        return
      }

      if (action === 'cancel') {
        this.currentRentId = id
        this.showCancelModal = true
        return
      }
    },
    onAcceptModalClose() {
      this.currentRentId = null
      this.showAcceptModal = false
    },
    onRejectModalClose() {
      this.currentRentId = null
      this.showRejectModal = false
    },
    onCancelModalClose() {
      this.currentRentId = null
      this.showCancelModal = false
    },
    onDeliverModalClose() {
      this.currentRentId = null
      this.showDeliverModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.child-trip-index-badge {
  border-radius: 50%;
  padding: 2px;
  margin-left: 8px;
  color: #fff;
  width: 23px;
  height: 23px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
</style>
