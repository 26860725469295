<template lang="">
  <div>
    <VehicleRentalIndex
      :fst-id="`vehicle-rentals`"
      :qso="getEndpointOptions"
      :haveRiderColumn="false"
    />
  </div>
</template>
<script>
import VehicleRentalIndex from '@/composites/vehicle-rental/index/Index.vue'
export default {
  name: 'ViewRiderUserRents',
  components: {
    VehicleRentalIndex,
  },

  computed: {
    getId() {
      return this.$route.params.id
    },
    getEndpointOptions() {
      return {
        append: `&user=${this.$route.params.id}`,
        prepend: `?`,
      }
    },
  },
}
</script>
<style lang="scss"></style>
